import { StudyNoticeListItem } from "types"; 

const StudyNoticeMock : StudyNoticeListItem[] = [

    {
        studyNumber : 1,
        studyNoticeNumber : 1,
        studyNoticeContent : "첫번째 공지사항입니다."
    },

    {
        studyNumber : 1,
        studyNoticeNumber : 2,
        studyNoticeContent : "두번째 공지사항입니다."        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 3,
        studyNoticeContent : "세번째 입니다. 제발 확인 부탁드립니다."        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 4,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 5,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 6,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 7,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 8,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 9,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 10,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 11,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    },
    {
        studyNumber : 1,
        studyNoticeNumber : 12,
        studyNoticeContent : "공지사항입니다. 공지사항입니다. 공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다.공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. 공지사항입니다. "        
    }
]

export  default StudyNoticeMock;