// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/no-cam-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#live {
    margin-left: -58px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    display: none;
  }
  #local-video {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    width: 250px;
    border-radius: 10px 10px;
    /* -webkit-transform: scaleX(-1); */
    transform: scaleX(-1);
    margin: 16px;
    /* border: 2px solid #fff; */
  }
  #remote-video {
    /* position: absolute;
    max-width: 100%;
    height: 100%; */
    border-radius: 10px 10px;
  }
  #end-call {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 10px 10px;
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }

  #end-call:hover {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/PeerJs/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,sBAAsB;;IAEtB,aAAa;EACf;EACA;IACE;;cAEU;IACV,YAAY;IACZ,wBAAwB;IACxB,mCAAmC;IACnC,qBAAqB;IACrB,YAAY;IACZ,4BAA4B;EAC9B;EACA;IACE;;mBAEe;IACf,wBAAwB;EAC1B;EACA;IACE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,4BAA4B;IAC5B,0BAA0B;IAC1B,yDAAmD;EACrD;;EAEA;IACE,eAAe;EACjB","sourcesContent":["#live {\n    margin-left: -58px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: flex;\n    flex-direction: column;\n\n    display: none;\n  }\n  #local-video {\n    /* position: absolute;\n    bottom: 0;\n    left: 0; */\n    width: 250px;\n    border-radius: 10px 10px;\n    /* -webkit-transform: scaleX(-1); */\n    transform: scaleX(-1);\n    margin: 16px;\n    /* border: 2px solid #fff; */\n  }\n  #remote-video {\n    /* position: absolute;\n    max-width: 100%;\n    height: 100%; */\n    border-radius: 10px 10px;\n  }\n  #end-call {\n    margin-left: 20px;\n    width: 30px;\n    height: 30px;\n    border-radius: 10px 10px;\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n    background-image: url(../../assets/no-cam-icon.png);\n  }\n\n  #end-call:hover {\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
