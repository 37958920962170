// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/search-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-inputbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-inputbox-container {
    width: 449px;
    height: 38px;

    padding: 4px 15px;

    border-radius: 30px;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 0 2px rgba(24, 160, 251, 0.5) inset;

    display: flex;
    align-items: center;
    gap: 4px;
}

.search-inputbox-container:hover {
    background-color: rgba(255,255,255,1);;
    box-shadow: 0 0 0 2px rgba(24,160,251,1) inset;
}

.search-input {
    flex: 1 1;

    border: none;
    background: none;
    outline: none;

    color: rgba(176,176,176,1);

    font-size: 21px;
    font-weight: 400;
}

.search-icon {
    width: 100%;
    height: 100%;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: 50% 50%;
    background-size: 100% 100%;
}

.icon-button {
    border-radius: 50% 50%;
    width: 24px;
    height: 24px;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SearchInputBox/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;;IAEZ,iBAAiB;;IAEjB,mBAAmB;IACnB,qCAAqC;IACrC,mDAAmD;;IAEnD,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,qCAAqC;IACrC,8CAA8C;AAClD;;AAEA;IACI,SAAO;;IAEP,YAAY;IACZ,gBAAgB;IAChB,aAAa;;IAEb,0BAA0B;;IAE1B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,yDAAmD;IACnD,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,qCAAqC;EACvC","sourcesContent":[".search-inputbox {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.search-inputbox-container {\n    width: 449px;\n    height: 38px;\n\n    padding: 4px 15px;\n\n    border-radius: 30px;\n    background-color: rgba(255,255,255,1);\n    box-shadow: 0 0 0 2px rgba(24, 160, 251, 0.5) inset;\n\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n\n.search-inputbox-container:hover {\n    background-color: rgba(255,255,255,1);;\n    box-shadow: 0 0 0 2px rgba(24,160,251,1) inset;\n}\n\n.search-input {\n    flex: 1;\n\n    border: none;\n    background: none;\n    outline: none;\n\n    color: rgba(176,176,176,1);\n\n    font-size: 21px;\n    font-weight: 400;\n}\n\n.search-icon {\n    width: 100%;\n    height: 100%;\n\n    background-image: url(../../assets/search-icon.png);\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n}\n\n.icon-button {\n    border-radius: 50% 50%;\n    width: 24px;\n    height: 24px;\n    \n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .icon-button:hover {\n    cursor: pointer;\n    background-color: rgba(0, 0, 0, 0.05);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
