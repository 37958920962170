// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/eye-off-icon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/eye-on-icon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/email-rewriting-ico.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eye-off-icon {
    width: 21px;
    height: 21px;
  
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: 50% 50%;
    background-size: 100% 100%;
  }
  .eye-off-icon:hover{
    cursor: pointer;
  }


.eye-on-icon {
    width: 21px;
    height: 21px;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-position: 50% 50%;
    background-size: 100% 100%;
}
.eye-on-icon:hover{
    cursor: pointer;
}

.email-rewriting-icon{
    width: 21px;
    height: 21px;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-position: 50% 50%;
    background-size: 100% 100%;
}
.email-rewriting-icon:hover{
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,yDAA8C;IAC9C,4BAA4B;IAC5B,0BAA0B;EAC5B;EACA;IACE,eAAe;EACjB;;;AAGF;IACI,WAAW;IACX,YAAY;;IAEZ,yDAA6C;IAC7C,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,yDAAqD;IACrD,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB","sourcesContent":[".eye-off-icon {\n    width: 21px;\n    height: 21px;\n  \n    background-image: url(assets/eye-off-icon.png);\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n  }\n  .eye-off-icon:hover{\n    cursor: pointer;\n  }\n\n\n.eye-on-icon {\n    width: 21px;\n    height: 21px;\n\n    background-image: url(assets/eye-on-icon.png);\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n}\n.eye-on-icon:hover{\n    cursor: pointer;\n}\n\n.email-rewriting-icon{\n    width: 21px;\n    height: 21px;\n\n    background-image: url(assets/email-rewriting-ico.png);\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n}\n.email-rewriting-icon:hover{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
