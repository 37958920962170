import { JoinStudyListItem } from 'types';

const joinStudyListMock: JoinStudyListItem[] = [
    {
        title: '방제목1',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목2',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목3',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목4',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목5',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목7',
        category: '회화',
        authority: '방장',
        progress: '70%',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
];

export default joinStudyListMock;
