// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-todolist-inputbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-todolist-inputbox-container {
    height: 31px;
    width: 100%;

    display: flex;
    align-items: center;
    border-radius: 5px;

    background-color: rgba(217,217,217,1);
    box-shadow: 0 0 0 2px rgba(24, 160, 251, 0.5) inset;
}

.my-todolist-inputbox-container:hover {
    background-color: rgba(255,255,255,1);;
    box-shadow: 0 0 0 2px rgba(24,160,251,1) inset;
}

.my-todolist-input {
    border: none;
    background: none;
    outline: none;

    flex: 1 1;
    padding: 0 10px;

    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/MyToDoListInputBox/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB,qCAAqC;IACrC,mDAAmD;AACvD;;AAEA;IACI,qCAAqC;IACrC,8CAA8C;AAClD;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;;IAEb,SAAO;IACP,eAAe;;IAEf,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".my-todolist-inputbox {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.my-todolist-inputbox-container {\n    height: 31px;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    border-radius: 5px;\n\n    background-color: rgba(217,217,217,1);\n    box-shadow: 0 0 0 2px rgba(24, 160, 251, 0.5) inset;\n}\n\n.my-todolist-inputbox-container:hover {\n    background-color: rgba(255,255,255,1);;\n    box-shadow: 0 0 0 2px rgba(24,160,251,1) inset;\n}\n\n.my-todolist-input {\n    border: none;\n    background: none;\n    outline: none;\n\n    flex: 1;\n    padding: 0 10px;\n\n    color: rgba(0, 0, 0, 0.7);\n    font-size: 17px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
