import { MyToDoItem } from "../types";

const myToDoListMock : MyToDoItem[] = [
  // {
  //   userListNumber: 1,
  //   userListContent: "스터디 자료 파일 다운받고 미리 숙지하기212321dfddfdfd1231232131231232131231212321312321312321312321321321312321",
  //   userListCheck: false,
  // },
  // {
  //   userListNumber: 2,
  //   userListContent: "안녕하세요2",
  //   userListCheck: false,
  // },
  // {
  //   userListNumber: 3,
  //   userListContent: "안녕하세요3",
  //   userListCheck: true,
  // },
  // {
  //   userListNumber: 4,
  //   userListContent: "스터디 자료 파일 다운받고 미리 숙지하기212321dfddfdfd1231232131231232131231212321312321312321312321321321312321",
  //   userListCheck: true,
  // },
  // {
  //   userListNumber: 5,
  //   userListContent: "안녕하세요5",
  //   userListCheck: false,
  // },
  // {
  //   userListNumber: 6,
  //   userListContent: "스터디 자료 파일 다운받고 미리 숙지하기212321dfddfdfd1231232131231232131231212321312321312321312321321321312321",
  //   userListCheck: true,
  // },
  // {
  //   userListNumber: 7,
  //   userListContent: "안녕하세요7",
  //   userListCheck: true,
  // }
];

export default myToDoListMock;