// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputbox {
    margin: 0;
    width: 400px;
    height: 50px;

}

.inputbox-label {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-container {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 14px;

    display: flex;
    align-items: center;
}

.inputbox-container-error {
    border-bottom: 1px solid rgba(255, 0, 0, 0.7);
    padding: 11px 16px 11px 0;

    display: flex;
    align-items: center;

    background-color: rgba(250, 250, 250, 1);
}

.input {
    border: none;
    background: none;
    outline: none;

    flex: 1 1;

    color: rgba(0, 0, 0, 0.7);
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-message {
    margin-top: 1px;

    color: rgba(255, 0, 0, 0.7);
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}`, "",{"version":3,"sources":["webpack://./src/components/InputBox/style.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6CAA6C;IAC7C,yBAAyB;;IAEzB,aAAa;IACb,mBAAmB;;IAEnB,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;;IAEb,SAAO;;IAEP,yBAAyB;IACzB,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;;IAEf,2BAA2B;IAC3B,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".inputbox {\n    margin: 0;\n    width: 400px;\n    height: 50px;\n\n}\n\n.inputbox-label {\n    margin-bottom: 5px;\n    color: rgba(0, 0, 0, 0.7);\n    font-family: Spoqa Han Sans Neo;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 140%;\n}\n\n.inputbox-container {\n    border: 1px solid rgba(0, 0, 0, 0.3);\n    border-radius: 5px;\n    padding: 10px 14px;\n\n    display: flex;\n    align-items: center;\n}\n\n.inputbox-container-error {\n    border-bottom: 1px solid rgba(255, 0, 0, 0.7);\n    padding: 11px 16px 11px 0;\n\n    display: flex;\n    align-items: center;\n\n    background-color: rgba(250, 250, 250, 1);\n}\n\n.input {\n    border: none;\n    background: none;\n    outline: none;\n\n    flex: 1;\n\n    color: rgba(0, 0, 0, 0.7);\n    font-family: Spoqa Han Sans Neo;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 140%;\n}\n\n.inputbox-message {\n    margin-top: 1px;\n\n    color: rgba(255, 0, 0, 0.7);\n    font-family: Spoqa Han Sans Neo;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 140%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
