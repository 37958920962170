// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/check-button-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-join-notice-list-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.room-join-notice-list-item-icon-box {
    margin-top: 10px;
    width: 15px;
    height: 15px;
    align-items: center;
}
.room-join-notice-list-item-icon {
    width: 100%;
    height: 100%;

    border-radius: 50% 50%;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: 50% 50%;
    background-size: 100% 100%;
}
.room-join-notice-common-list-item-contents {
    width: 100%;
    border: none;
    outline: none;  

    color: #000;

    font-family: Spoqa Han Sans Neo;
    font-size: 25px;
    font-weight: 400;

    resize: none;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/RoomJoinMoadalNoticeItem/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;;IAEZ,sBAAsB;;IAEtB,yDAAmD;IACnD,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;;IAEb,WAAW;;IAEX,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;;IAEhB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".room-join-notice-list-item-box {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 5px;\n}\n\n.room-join-notice-list-item-icon-box {\n    margin-top: 10px;\n    width: 15px;\n    height: 15px;\n    align-items: center;\n}\n.room-join-notice-list-item-icon {\n    width: 100%;\n    height: 100%;\n\n    border-radius: 50% 50%;\n\n    background-image: url(assets/check-button-icon.png);\n    background-position: 50% 50%;\n    background-size: 100% 100%;\n}\n.room-join-notice-common-list-item-contents {\n    width: 100%;\n    border: none;\n    outline: none;  \n\n    color: #000;\n\n    font-family: Spoqa Han Sans Neo;\n    font-size: 25px;\n    font-weight: 400;\n\n    resize: none;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
