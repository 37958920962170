// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notice-normal-modal{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
    background-color : rgba(0,0,0,0.4);
    font-family: 'Spoqa Han Sans Neo';
}


.notice-card{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 900px;
    height: 870px;
    transform: translate(-50%, -50%);

    border-radius: 15px;
    border: 3px solid rgba(24, 160, 251, 1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(232, 241, 255, 1);
    border-radius: 30px;    
}

.notice-common-content-box {
    margin-bottom: 80px;

    width: 770px;
    height: 570px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;

    background-color: rgba(255, 255, 255, 1);
}

.notice-common-contents-left-box {
    width: 755px;
    height: 570px;

    display: flex;
    flex-direction: column;

    background-color: rgba(255, 255, 255, 1);
}`, "",{"version":3,"sources":["webpack://./src/views/modal/NoticeModal/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,eAAe;IACf,YAAY;IACZ,kCAAkC;IAClC,iCAAiC;AACrC;;;AAGA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,aAAa;IACb,gCAAgC;;IAEhC,mBAAmB;IACnB,uCAAuC;;IAEvC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;IAEnB,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;;IAEnB,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,mBAAmB;;IAEnB,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,sBAAsB;;IAEtB,wCAAwC;AAC5C","sourcesContent":["#notice-normal-modal{\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    z-index: 100;\n    background-color : rgba(0,0,0,0.4);\n    font-family: 'Spoqa Han Sans Neo';\n}\n\n\n.notice-card{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 900px;\n    height: 870px;\n    transform: translate(-50%, -50%);\n\n    border-radius: 15px;\n    border: 3px solid rgba(24, 160, 251, 1);\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    background-color: rgba(232, 241, 255, 1);\n    border-radius: 30px;    \n}\n\n.notice-common-content-box {\n    margin-bottom: 80px;\n\n    width: 770px;\n    height: 570px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    border-radius: 10px;\n\n    background-color: rgba(255, 255, 255, 1);\n}\n\n.notice-common-contents-left-box {\n    width: 755px;\n    height: 570px;\n\n    display: flex;\n    flex-direction: column;\n\n    background-color: rgba(255, 255, 255, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
