import { RecommendationStudyRoomItem } from "../types";

const recommendationStudyListMock: RecommendationStudyRoomItem[] = [
  // {
  //   studyNumber: 1,
  //   studyName: '안녕하세요',
  //   studyEndDate: '2023년 09월 15일',
  //   studyPersonnel: '5 / 14',
  //   studyCoverImageUrl: 'http://www.chemicalnews.co.kr/news/photo/202106/3636_10174_4958.jpg',
  // },
  // {
  //   studyNumber: 2,
  //   studyName: '안녕하세요 강아지입니다.',
  //   studyEndDate: '2023년 09월 11일',
  //   studyPersonnel: '5 / 14',
  //   studyCoverImageUrl: 'https://images.mypetlife.co.kr/content/uploads/2022/12/16165036/IMG_7743-edited-scaled.jpg',
  // },
  // {
  //   studyNumber: 3,
  //   studyName: '안녕하세요 원숭이입니다.',
  //   studyEndDate: '2023년 01월 11일',
  //   studyPersonnel: '1 / 14',
  //   studyCoverImageUrl: 'https://newsimg-hams.hankookilbo.com/2022/03/31/71f341a2-0b9d-4fbd-81e4-0d706e714767.jpg',
  // },
  // {
  //   studyNumber: 4,
  //   studyName: '안녕하세요 111원숭이입니다.',
  //   studyEndDate: '2023년 01월 30일',
  //   studyPersonnel: '3 / 11',
  //   studyCoverImageUrl: '',
  // },
  // {
  //   studyNumber: 5,
  //   studyName: '안녕하세요 111원숭이입니다12345678898990001.',
  //   studyEndDate: '2023년 01월 30일',
  //   studyPersonnel: '3 / 11',
  //   studyCoverImageUrl: '',
  // }
];

export default recommendationStudyListMock;
