import { JoinedStudyListItem } from 'types';

const joinedStudyListMock: JoinedStudyListItem[] = [
    {
        title: '방제목1',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목2',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목3',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목4',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목5',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
    {
        title: '방제목6',
        category: '회화',
        authority: '방장',
        endDays: '2023년 11월 30일',
        totalDays: '120일',
        joinDays: '30일',
        absentDays: '5일',
        lateDays: '10일',
    },
];

export default joinedStudyListMock;
