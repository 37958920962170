import { UserGradeList } from 'types';

const userGradeListMock: UserGradeList[] = [
    {
        userNickname: '힘가둑12345',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '방장',
    },
    {
        userNickname: '힘가둑1',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑2',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑3',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑4',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑5',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑6',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑7',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑8',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑9',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑10',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑11',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑12',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑13',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑14',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑15',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑16',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑17',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑18',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '힘가둑19',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '아아아',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },
    {
        userNickname: '뭔데요',
        userProfileImageUrl: 'https://post-phinf.pstatic.net/MjAxODExMTZfMjY3/MDAxNTQyMzI4OTA4NDA0.rU5IsgGU93vruo75AJaQQPubrmEqWOEtsICOF050V_0g.XY7ATCDs20_h7QhP2SqHwBFGcRqpQeYirMaiFyxUt3Eg.JPEG/2-20181116-092159.jpg?type=w1200',
        studyGrade: '일반'
    },

];

export default userGradeListMock;