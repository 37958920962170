// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-common-list-item-contents {
    margin-left: 15px;

    width: 100%;
    min-width: 550px;

    border: none;
    outline: none;

    font-family: 'Spoqa Han Sans Neo';
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.18px;

    resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/NoticeItem/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,WAAW;IACX,gBAAgB;;IAEhB,YAAY;IACZ,aAAa;;IAEb,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,uBAAuB;;IAEvB,YAAY;AAChB","sourcesContent":[".notice-common-list-item-contents {\n    margin-left: 15px;\n\n    width: 100%;\n    min-width: 550px;\n\n    border: none;\n    outline: none;\n\n    font-family: 'Spoqa Han Sans Neo';\n    font-size: 20px;\n    font-weight: 400;\n    letter-spacing: -0.18px;\n\n    resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
